import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import { Container } from '@components'
import { Button } from '@elements'
import { user } from '@graphql'
import { useLocationWithState, useModal, useUrlSearchParams } from '@hooks'
import { order as graphqlOrder } from '@graphql'
import { CacheUser, GolfClub, Order, ResponseType } from '@types'
import './checkout-success.scss'
import { cacheCatalogueFilterParams } from '@cache'
import { CheckoutSuccessModal } from '../../pages-components/checkout-success'
import { css } from '@emotion/css'
import cls from 'classnames'

type checkoutSuccessStatusType = {
  header: string
  content: string
  firstButtonLabel: string
  firstButtonNavigation: string
  secondButtonLabel: string
  secondButtonNavigation: string
}

const CheckoutSuccess: React.FC = () => {
  const location = useLocationWithState<{
    submitTitle: string
    submitUrl: string
    isSell: boolean
    checkoutSuccessValue: string
  }>()
  const { getUrlSearchParam } = useUrlSearchParams('orderId')
  const orderId = getUrlSearchParam()

  const { getUrlSearchParam: getUrlSearchParamType } = useUrlSearchParams('type')
  const type = getUrlSearchParamType()

  const { openModal } = useModal<{ golfClub: GolfClub }>('AddBoughtGolfClubToBagModal')
  const { data: orderData } = useQuery<ResponseType<Order>>(graphqlOrder.FetchOrder, {
    variables: { id: orderId },
    skip: !orderId
  })
  const { data } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, { fetchPolicy: 'cache-only' })
  const currentUser = data && data.res
  const order = orderData && orderData.res

  const email = currentUser?.email
  const checkoutSuccess = location?.state?.checkoutSuccessValue
  const checkoutSuccessLocal = localStorage.getItem('checkoutSuccessStatus')
  const catalogueFilterParamsUrl = cacheCatalogueFilterParams()
  const isGolfCLub = localStorage.getItem('fetchModel') === 'GOLF_CLUB_MODEL'

  useEffect(() => {
    if (order?.golfClub) {
      openModal({ golfClub: order.golfClub })
    }
  }, [order])

  useEffect(() => {
    if (checkoutSuccess) {
      localStorage.setItem('checkoutSuccessStatus', checkoutSuccess) ///  localStorage item 'checkoutSuccessStatus' remove on Page.tsx
    }
  }, [checkoutSuccess])

  useEffect(() => {
    if (!checkoutSuccessLocal && !checkoutSuccess) {
      navigate('/home')
    }
  }, [checkoutSuccess, checkoutSuccessLocal])

  if (!checkoutSuccessLocal && !checkoutSuccess) {
    return null
  }

  let checkoutSuccessStatus: checkoutSuccessStatusType = {} as checkoutSuccessStatusType
  switch (checkoutSuccess || checkoutSuccessLocal) {
    case 'buy-now':
      checkoutSuccessStatus = {
        header: 'CONGRATULATIONS! AT TOURDAY GOLF, BUYING GOLF CLUBS IS FUN.',
        content: `Confirmation letter has been sent to ${email}`,
        firstButtonLabel: 'MY ORDER HISTORY',
        firstButtonNavigation: '/account/order-history',
        secondButtonLabel: 'CONTINUE BROWSING',
        secondButtonNavigation: `/catalogue/${catalogueFilterParamsUrl}`
      }
      break
    case 'sell-now':
      checkoutSuccessStatus = {
        header: 'IN THE HOLE!! CONGRATS, GET READY TO SHIP',
        content: isGolfCLub
          ? 'Congratulations! Your golf club has been successfully sold.'
          : 'Congratulations! You sold your item. Get ready to ship!',
        firstButtonLabel: 'MY SALES HISTORY',
        firstButtonNavigation: '/account/store/my-sales-history',
        secondButtonLabel: 'HOW TO SHIP',
        secondButtonNavigation: '/about/shipping'
      }
      break
    case 'set-buy-price':
      checkoutSuccessStatus = {
        header: 'GOOD CHOICE AND GOOD LUCK!',
        content: isGolfCLub
          ? 'You have successfully Set the Buy Price. Track club status in “My account” section - “My Offer Book”'
          : 'That was easy! You have successfully made an offer! Track activity in your Locker!',
        firstButtonLabel: 'My Offer Book',
        firstButtonNavigation: '/account/my-offer-book',
        secondButtonLabel: 'CONTINUE BROWSING',
        secondButtonNavigation: '/catalogue'
      }
      break
    case 'set-sell-price':
      checkoutSuccessStatus = {
        header: '🤞 GOOD LUCK',
        content: isGolfCLub
          ? 'You have successfully “Set Sell Price”. Track club status in the “My Golf Item Inventory” portal.'
          : 'That was easy! You have successfully listed your item! Track status in the “My Golf Item Inventory” portal!',
        firstButtonLabel: 'MY GOLF ITEM INVENTORY',
        firstButtonNavigation: '/account/store/my-golf-club-inventory',
        secondButtonLabel: 'HOW TO SHIP',
        secondButtonNavigation: '/about/shipping'
      }
      break
  }

  const renderContent = () => {
    if (type === 'buy-now' || type === 'set-buy-price') return <CheckoutSuccessModal />

    return (
      <div className={'checkout-success'}>
        <section className={'checkout-success-card'}>
          <Container withCard>
            <h2 className={'checkout-success-title'}>{checkoutSuccessStatus.header}</h2>
            <h3 className={'checkout-success-subtitle'}>{checkoutSuccessStatus.content}</h3>
            <div className={'checkout-success-button-container'}>
              <Button
                type={'black'}
                size={'md'}
                className={'checkout-button'}
                to={checkoutSuccessStatus.firstButtonNavigation}
              >
                {checkoutSuccessStatus.firstButtonLabel}
              </Button>
              <Button
                type={'white'}
                size={'md'}
                className={'checkout-button'}
                to={checkoutSuccessStatus.secondButtonNavigation}
              >
                {checkoutSuccessStatus.secondButtonLabel}
              </Button>
            </div>
          </Container>
        </section>
      </div>
    )
  }

  return (
    <div
      className={cls({
        [css`
          background-color: #f5f5f5;
          margin-top: 100px;
          padding: 50px 50px;
          @media (max-width: 768px) {
            margin-top: 60px;
            padding: 15px 15px;
          }
        `]: type === 'buy-now' || type === 'set-buy-price'
      })}
    >
      {renderContent()}
    </div>
  )
}

export default CheckoutSuccess
